import axios from 'axios'

export const createClient = () => {
    const client = axios.create()

    client.interceptors.response.use(response => response, globalErrorHandler)
    return client
}

let globalErrorHandler: (error: any) => any = async error => Promise.reject(error)

export const attachGlobalAxiosErrorHandler = (customHandler: (error: any) => any) => {
    globalErrorHandler = customHandler
}
