
/**
 * Deletes an element from the given array if it exists and returns a new array with the result
 * @param array initial array
 * @param element to be added
 */
export function removeElement(array: any[], element: any) {
    const newArray = [...array];
    const idx = newArray.indexOf(element);
    if (idx > -1) {
        newArray.splice(idx,1);
    }
    return newArray
}

export function addElement(array: any[], element: any) {
    const newArray = [...array];
    const idx = newArray.indexOf(element);
    if (idx === -1) {
        newArray.push(element)
    }
    return newArray
}

/**
 * Inserts an element into an array.
 * By default the element is inserted ont he first position
 * @param array initial array
 * @param element to be inserted
 * @param index position for insertion
 */
export function insertElement(array: any[], element: any, index = 0) {
    const newArray = [...array];
    if (newArray.length < index) {
        return newArray
    }
    newArray.splice(index, 0, element);
    return newArray
}

/**
 * Inserts an element into an array.
 * By default the element is inserted ont he first position
 * @param array initial array
 * @param element to be inserted
 * @param index position for insertion
 */
export function replaceElement(array: any[], element: any, index = 0) {
    const newArray = [...array];
    if (newArray.length < index) {
        return newArray
    }
    newArray.splice(index, 1, element);
    return newArray
}


