import React, {useCallback, useState} from 'react'
import {Button, Steps} from 'antd'
import {i18n} from '../../../../i18n'
import {getIndexForEnum} from '../../../../utils/helper'
import {TherapyStatus} from '../../../../utils/enums'
import {sharedStyles} from '../../../../style/shared_styles'
import {ButtonStyle} from '../../../../style/button'
import {getNextTherapyStatus} from '../../../../utils/medication_helper'
import {useStores} from '../../../../store'
import ArchiveModal from '../subcomponents/ArchiveModal'
import {CustomModal} from '../../../common'
import VerifyTherapyContent from '../../actions/VerifyTherapyContent'
import ObservationContent from '../../actions/ObservationContent'

/**
 * Component displaying the status of the therapy plan.
 */
const StepsView: React.FC<StepsViewProps> = (props: StepsViewProps) => {
    const [archiveModalVisible, setArchiveModalVisible] = useState(false)
    const [verifyModalVisible, setVerifyModalVisible] = useState(false)
    const [isSideEffectDisplayed, setIsSideEffectDisplayed] = useState(false) // inside the verify modal
    const [exportLoading, setExportLoading] = useState(false)
    const {therapyStore, userStore, patientStore} = useStores()

    const getStepState = useCallback(
        (currentStep: number) => {
            const index: number = getIndexForEnum(TherapyStatus, props.status)
            if (currentStep < index) {
                return 'finish'
            }
            if (currentStep === index) {
                return 'process'
            }
            return 'wait'
        },
        [props.status]
    )

    const updateStatus = async () => {
        const newStatus = getNextTherapyStatus(props.status)
        if (newStatus === TherapyStatus.CLOSED) {
            setArchiveModalVisible(true)
            return
        }
        if (newStatus) {
            setVerifyModalVisible(true)
        }
    }

    const closeModal = () => {
        setVerifyModalVisible(false)
        setIsSideEffectDisplayed(false)
    }

    const onVerifyModalSave = async () => {
        const newStatus = getNextTherapyStatus(props.status) as TherapyStatus // verified when modal was opened
        if (newStatus === TherapyStatus.TO_CHECK) {
            await therapyStore.requestToCheckTherapy()
        }
        if (newStatus === TherapyStatus.RELEASED && !isSideEffectDisplayed) {
            setIsSideEffectDisplayed(true)
            return
        }
        closeModal()
    }

    const onRetryTherapiesExport = async () => {
        setExportLoading(true)
        await therapyStore.exportTherapies(patientStore.patientOverview?.patient.id || '')
        setExportLoading(false)
    }

    const displayButtonCond =
        (getNextTherapyStatus(props.status) !== null && props.status !== TherapyStatus.TO_CHECK) ||
        (props.status === TherapyStatus.TO_CHECK && userStore.isDoctor) // any status except TO_CHECk if current user isn't doctor

    const hasOperationsErrors: boolean | undefined | null = patientStore.patientOverview?.hasOperationsErrors

    return (
        <div style={{...sharedStyles.inlineContainer, alignItems: 'center'}}>
            <Steps type='navigation' current={getIndexForEnum(TherapyStatus, props.status)}>
                <Steps.Step status={getStepState(0)} title={i18n.t(`therapy.status.${TherapyStatus.PLANNED}`)} />
                <Steps.Step status={getStepState(1)} title={i18n.t(`therapy.status.${TherapyStatus.TO_CHECK}`)} />
                <Steps.Step status={getStepState(2)} title={i18n.t(`therapy.status.${TherapyStatus.RELEASED}`)} />
                <Steps.Step status={getStepState(3)} title={i18n.t(`therapy.status.${TherapyStatus.CLOSED}`)} />
            </Steps>
            {displayButtonCond && !hasOperationsErrors && (
                <div>
                    <Button type={'default'} shape={'round'} style={ButtonStyle.primary} onClick={updateStatus}>
                        {i18n.t(`button.status.${getNextTherapyStatus(props.status)}`)}
                    </Button>
                </div>
            )}
            {patientStore.patientOverview?.patient.exportRequired && !hasOperationsErrors && (
                <div>
                    <Button
                        type={'default'}
                        shape={'round'}
                        style={ButtonStyle.danger}
                        loading={exportLoading}
                        onClick={async () => onRetryTherapiesExport()}>
                        {i18n.t('button.retryExport')}
                    </Button>
                </div>
            )}
            <ArchiveModal visible={archiveModalVisible} setVisible={setArchiveModalVisible} status={props.status} />
            <CustomModal title={i18n.t(`therapy.verify.title`)} open={verifyModalVisible} width={'85%'}>
                {isSideEffectDisplayed ? (
                    <ObservationContent
                        onCancel={closeModal}
                        onSave={onVerifyModalSave}
                        onBack={() => setIsSideEffectDisplayed(false)}
                        onCloseModal={closeModal}
                    />
                ) : (
                    <VerifyTherapyContent onCancel={closeModal} onSave={onVerifyModalSave} />
                )}
            </CustomModal>
        </div>
    )
}

type StepsViewProps = {
    status: TherapyStatus
}

export default StepsView
