import React from 'react'
import {InsuranceInfo} from '../../../../utils/types'
import EditDetailsEntry from '../subcomponents/EditDetailsEntry'
import {format} from 'date-fns'
import {Format} from '../../../../utils/constants'
import {i18n} from '../../../../i18n'
import {ClientKeys, EditInsuranceKey} from '../../actions/utils/keys'

/**
 * Component displaying patient insurance data.
 */
const InsurancePanel: React.FC<InsurancePanelProps> = (props: InsurancePanelProps) => {
    const onEditDetails = (input: ClientKeys) => props.onEdit(input)
    return (
        <div style={{display: 'flex'}}>
            <div style={{flex: 1}}>
                <EditDetailsEntry
                    title={i18n.t('userDataTab.insurance.name.title')}
                    value={props.insurance.name}
                    onEdit={() => onEditDetails(EditInsuranceKey.name)}
                    extraInfo={i18n.t('userDataTab.insurance.name.extra')}
                    hasOperationsErrors={props.hasOperationsErrors}
                />
                <EditDetailsEntry
                    title={i18n.t('userDataTab.insurance.number.title')}
                    value={props.insurance.number}
                    onEdit={() => onEditDetails(EditInsuranceKey.number)}
                    extraInfo={i18n.t('userDataTab.insurance.number.extra')}
                    hasOperationsErrors={props.hasOperationsErrors}
                />
                <EditDetailsEntry
                    title={i18n.t('userDataTab.insurance.status')}
                    value={props.insurance.status ? i18n.t(`insurance.status.${props.insurance.status}`) : undefined}
                    onEdit={() => onEditDetails(EditInsuranceKey.status)}
                    hasOperationsErrors={props.hasOperationsErrors}
                />
            </div>
            <div style={{flex: 1}}>
                <EditDetailsEntry
                    title={i18n.t('userDataTab.insurance.validFrom')}
                    value={props.insurance.validFrom ? format(props.insurance.validFrom, Format.DateFormat) : undefined}
                    onEdit={() => onEditDetails(EditInsuranceKey.validFrom)}
                    hasOperationsErrors={props.hasOperationsErrors}
                />
                <EditDetailsEntry
                    title={i18n.t('userDataTab.insurance.validTo')}
                    value={props.insurance.validTo ? format(props.insurance.validTo, Format.DateFormat) : undefined}
                    onEdit={() => onEditDetails(EditInsuranceKey.validTo)}
                    hasOperationsErrors={props.hasOperationsErrors}
                />
            </div>
        </div>
    )
}

type InsurancePanelProps = {
    insurance: InsuranceInfo
    onEdit: (input: ClientKeys) => void
    hasOperationsErrors?: boolean | undefined | null
}

export default InsurancePanel
