/**
 * I18n index containing translation configurations. src/i18n/index.tsx
 */
import {I18n} from 'i18n-js'
import {useState} from "react";
import { useStores } from "../store";

export const i18n = new I18n()

export  enum Locale {
    EN = 'en',
    DE = 'de'
}

i18n.locale = Locale.DE
i18n.enableFallback = true

export function setI18nLocale(locale: Locale) {
    i18n.locale = locale
}

/**
 * load translation files
 * @param en - JSON with en translations
 * @param de - JSON with de translations
 */
export function loadTranslations(en = {}, de = {}) {
    i18n.store({
        en,
        de
    })
}

/**
 * By default a missing translation will be displayed as '[missing "locale.key" translation]', regardless of used locale
 * @param key, the key used for translation
 */
export function hasTranslation(key: string) {
    return !i18n.t(key).startsWith('[missing ')
}


export function useLocale() : [Locale, (loc: Locale) => void] {
    const [locale, setCurrentLocale] = useState<Locale>(i18n.locale as Locale)
    const {userStore} = useStores();

    const setLocale = (loc: Locale) => {
        setI18nLocale(loc);
        setCurrentLocale(loc);
        userStore.getUserConfigsServer();
    }

    return [locale, setLocale]
}

export default I18n;
