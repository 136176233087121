import React, {useMemo} from 'react'
import {Format} from '../../utils/constants'
import moment from 'moment'
import {DatePicker} from 'antd'
import {InputLabelProps} from './InputLabel'
import {sharedStyles} from '../../style/shared_styles'
import {ErrorField, InputLabel} from './index'
import {ErrorFieldProps} from './ErrorField'
import {i18n, Locale, useLocale} from '../../i18n'
import {disableDateSelection} from './utils/inputHelper'
import {useDefaultValueSet} from './utils/useDefaultValueSet'
import localeDe from 'antd/es/date-picker/locale/de_DE'
import localeEn from 'antd/es/date-picker/locale/en_US'
import 'moment/locale/de'
import {PickerLocale} from 'antd/es/date-picker/generatePicker'

/**
 * Custom component used for rendering a date time picker select.
 * This component is a generic one and will be used for all date selections.
 */
const DateInput: React.FC<DateInputProps> = (props: DateInputProps) => {
    const [currentLocale] = useLocale()
    useDefaultValueSet(props.value, props.onValueChanged, null, props.skipInitialize)

    const inputStyle = props.error ? sharedStyles.border : {}

    const onChange = (date: moment.Moment | null) => {
        const errorMessage = props.mandatory && !date ? i18n.t('common.errors.empty') : ''
        props.onValueChanged(date?.toDate(), errorMessage)
    }

    const {id, ...labelProps} = props

    // switch picker locale based on the user locale
    const datePickerLocale = useMemo<PickerLocale>(() => {
        return currentLocale === Locale.DE ? localeDe : localeEn
    }, [currentLocale])

    return (
        <div>
            <InputLabel {...labelProps} />
            <DatePicker
                id={id}
                disabledDate={(currentDate: moment.Moment) => disableDateSelection(currentDate?.toDate(), props.min, props.max)}
                onChange={onChange}
                format={Format.MomentFormat}
                style={{...inputStyle, width: '100%'}}
                defaultValue={props.value ? moment(props.value) : undefined}
                defaultPickerValue={props.defaultPickerValue ? moment(props.defaultPickerValue) : undefined}
                onBlur={props.onBlur}
                // https://github.com/ant-design/ant-design/issues/22499
                autoComplete={'off'}
                locale={datePickerLocale}
            />
            <ErrorField error={props.error} warn={props.warn} />
        </div>
    )
}

interface DateInputProps extends InputLabelProps, ErrorFieldProps {
    onValueChanged: (val: any, error?: string) => void
    value: Date
    id?: string
    min?: Date
    max?: Date
    defaultPickerValue?: Date
    onBlur?: () => void
}

export default DateInput
