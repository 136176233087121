import {DependencyList, EffectCallback, useEffect, useRef} from "react";

/**
 * UseEffect function that skips onDidMount execution.
 */
export function useExceptMount(effect: EffectCallback, deps?: DependencyList) {
    const mount = useRef(false)
    useEffect(() => {
        if (mount.current) {
            effect();
        } else {
            mount.current = true
        }
    }, deps)
}
