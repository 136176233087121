import React, {useState} from 'react'
import {i18n} from '../../../../i18n'
import {CustomModal, TitleText} from '../../../common'
import {TherapyGeneralInfo} from '../../../../utils/types'
import EditDetailsEntry from '../subcomponents/EditDetailsEntry'
import {addDays, format} from 'date-fns'
import {Format} from '../../../../utils/constants'
import {EditFilled} from '@ant-design/icons'
import {sharedStyles} from '../../../../style/shared_styles'
import EditTherapy from '../../actions/EditTherapy'

/**
 * Component displaying general information of a therapy plan.
 */
const BasicInfoSection: React.FC<BasicInfoSectionProps> = (props: BasicInfoSectionProps) => {
    const [editModalVisible, setEditModalVisible] = useState(false)

    return (
        <div>
            <div style={{...sharedStyles.inlineContainer, marginTop: '2%'}}>
                <TitleText text={i18n.t('therapyTab.basicSection.title')} />
                {props.actionsAvailable && <EditFilled style={{fontSize: 35}} onClick={() => setEditModalVisible(true)} />}
            </div>
            <div style={{display: 'flex'}}>
                <div style={{flex: 1}}>
                    <EditDetailsEntry title={i18n.t('therapyTab.basicSection.name')} value={props.therapyInfo.therapyName} />
                    <EditDetailsEntry
                        title={i18n.t('therapyTab.basicSection.startDate')}
                        value={format(props.therapyInfo.startDate, Format.DateFormat)}
                    />
                    <EditDetailsEntry title={i18n.t('therapyTab.basicSection.cycle')} value={props.therapyInfo.cycleDuration} />
                </div>
                <div style={{flex: 1}}>
                    <EditDetailsEntry title={i18n.t('therapyTab.basicSection.cycleNumber')} value={props.therapyInfo.cycleNumber} />

                    <EditDetailsEntry
                        title={i18n.t('therapyTab.basicSection.nextCycle')}
                        value={format(addDays(props.therapyInfo.startDate, props.therapyInfo.cycleDuration), Format.DateFormat)}
                    />
                    <EditDetailsEntry title={i18n.t('therapyTab.basicSection.doctor.label')} value={props.therapyInfo.doctor?.name} />
                </div>
                <div style={{flex: 1}}>
                    <EditDetailsEntry title={i18n.t('therapyTab.basicSection.height')} value={props.therapyInfo.height} />
                    <EditDetailsEntry title={i18n.t('therapyTab.basicSection.weight')} value={props.therapyInfo.weight?.toLocaleString(i18n.locale)} />
                    <EditDetailsEntry
                        title={i18n.t('therapyTab.basicSection.surface')}
                        value={props.therapyInfo.bodySurface?.toLocaleString(i18n.locale)}
                    />
                </div>
            </div>
            <EditDetailsEntry title={i18n.t('therapyTab.basicSection.comment')} value={props.therapyInfo.comment} />
            <CustomModal title={i18n.t('therapyTab.basicSection.edit.title')} open={editModalVisible}>
                <EditTherapy therapyInfo={props.therapyInfo} onCloseModal={() => setEditModalVisible(false)} />
            </CustomModal>
        </div>
    )
}

type BasicInfoSectionProps = {
    therapyInfo: TherapyGeneralInfo
    actionsAvailable: boolean
}

export default BasicInfoSection
