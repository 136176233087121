import React, {useState} from 'react'
import TherapyPlanHeader from './TherapyPlanHeader'
import BasicInfoSection from './BasicInfoSection'
import StepsView from './StepsView'
import TherapyMedicationTable from './TherapyMedicationTable'
import OtherMedicationTable from './OtherMedicationTable'
import RequestedMedicationTable from './RequestedMedicationTable'
import {i18n} from '../../../../i18n'
import {useStores} from '../../../../store'
import {Button} from 'antd'
import {ButtonStyle} from '../../../../style/button'
import {TherapyStatus} from '../../../../utils/enums'
import {sharedStyles} from '../../../../style/shared_styles'
import ArchiveModal from '../subcomponents/ArchiveModal'
import {observer} from 'mobx-react'
import ObservationsDisplay from '../subcomponents/ObservationsDisplay'

/**
 * Tab Component containing a therapy plan's data and functionalities.
 */
const TherapyPlanTab: React.FC<MedicationTabProps> = observer(props => {
    const [archiveModalVisible, setArchiveModalVisible] = useState(false)
    const [loadingPdf, setLoadingPdf] = useState(false)
    const {
        therapyStore,
        observationStore: {sideEffects, vitalData},
        patientStore: {patientOverview}
    } = useStores()

    const onlyActiveSE = sideEffects?.filter(se => se.hcpEnabled || se.isSystem) || []
    const onlyActiveVD = vitalData?.filter(vd => vd.hcpEnabled || vd.isSystem) || []
    // edit available if doesn't exist previous secondary operations which fails
    const hasOperationsErrors: boolean | undefined | null = patientOverview?.hasOperationsErrors

    const actionsAvailable = !props.inVerifyModal && therapyStore.canUpdateMedication && !hasOperationsErrors
    const therapyComplete = therapyStore.therapyComplete
    const status = therapyComplete?.generalInfo.status

    const generateReport = async () => {
        setLoadingPdf(true)
        if (therapyComplete && patientOverview) {
            await therapyStore.generateReport(therapyComplete.generalInfo.id, patientOverview.patient.id)
        }
        setLoadingPdf(false)
    }
    const onCreatePdf = therapyComplete ? generateReport : undefined
    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            {!props.inVerifyModal && (
                <TherapyPlanHeader
                    showMessage={!!therapyComplete && !therapyComplete.generalInfo.consentGiven}
                    onCreatePdf={onCreatePdf}
                    loadingPdf={loadingPdf}
                    hasOperationsErrors={hasOperationsErrors}
                />
            )}
            {therapyComplete ? (
                <>
                    {!props.inVerifyModal && (
                        <>
                            <StepsView status={therapyComplete.generalInfo.status} />
                            {(status === TherapyStatus.PLANNED || status === TherapyStatus.TO_CHECK) && (
                                <div style={sharedStyles.actionBtnRight}>
                                    {!hasOperationsErrors && (
                                        <Button
                                            type={'default'}
                                            shape={'round'}
                                            style={ButtonStyle.primary}
                                            onClick={() => setArchiveModalVisible(true)}>
                                            {i18n.t('button.archive')}
                                        </Button>
                                    )}
                                </div>
                            )}
                        </>
                    )}
                    <BasicInfoSection therapyInfo={therapyComplete.generalInfo} actionsAvailable={actionsAvailable} />
                    <TherapyMedicationTable meds={therapyComplete.therapyMedication} actionsAvailable={actionsAvailable} />
                    <RequestedMedicationTable meds={therapyComplete.requestedMedication} actionsAvailable={actionsAvailable} />
                    <OtherMedicationTable meds={therapyComplete.otherMedication} actionsAvailable={actionsAvailable} />
                    {therapyStore.showObservations && <ObservationsDisplay vitalData={onlyActiveVD} sideEffects={onlyActiveSE} disabled={true} />}
                    <ArchiveModal
                        visible={archiveModalVisible}
                        setVisible={setArchiveModalVisible}
                        onSave={() => setArchiveModalVisible(false)}
                        status={therapyComplete.generalInfo.status}
                    />
                </>
            ) : (
                <p>{i18n.t('common.errors.noData')}</p>
            )}
        </div>
    )
})

type MedicationTabProps = {
    inVerifyModal?: boolean
}

export default TherapyPlanTab
