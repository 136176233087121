import React, {useState} from 'react'
import {Collapse} from 'antd'
import {i18n} from '../../../../i18n'
import GeneralInfoPanel from './GeneralInfoPanel'
import ContactPanel from './ContactPanel'
import InsurancePanel from './InsurancePanel'
import ExtraInfoPanel from './ExtraInfoPanel'
import EditPatient from '../../actions/EditPatient'
import {CustomModal} from '../../../common'
import {PatientGeneralInfo, PatientOverviewType} from '../../../../utils/types'
import {ClientKeys} from '../../actions/utils/keys'

/**
 * Tab Component containing panels displaying patient data.
 */
const UserDataTab: React.FC<UserDataTabProps> = (props: UserDataTabProps) => {
    const [editModalVisible, setEditModalVisible] = useState(false)
    const [focusInput, setFocusInput] = useState<ClientKeys | undefined>(undefined)

    const onEdit = (input: ClientKeys) => {
        setFocusInput(input)
        setEditModalVisible(true)
    }

    // edit available if doesn't exist previous secondary operations which fails
    const hasOperationsErrors: boolean | undefined | null = props.patientOverview.hasOperationsErrors
    const patient: PatientGeneralInfo = props.patientOverview.patient
    return (
        <div>
            <Collapse defaultActiveKey={[]} ghost={true}>
                <Collapse.Panel header={i18n.t('userDataTab.header.general')} key='general'>
                    <GeneralInfoPanel onEdit={onEdit} patient={patient} hasOperationsErrors={hasOperationsErrors} />
                </Collapse.Panel>
                <Collapse.Panel header={i18n.t('userDataTab.header.contact')} key='contact'>
                    <ContactPanel onEdit={onEdit} patient={patient} hasOperationsErrors={hasOperationsErrors} />
                </Collapse.Panel>
                <Collapse.Panel header={i18n.t('userDataTab.header.insurance')} key='insurance'>
                    <InsurancePanel onEdit={onEdit} insurance={patient.insurance} hasOperationsErrors={hasOperationsErrors} />
                </Collapse.Panel>
                <Collapse.Panel header={i18n.t('userDataTab.header.extraInfo')} key='extraInfo'>
                    <ExtraInfoPanel onEdit={onEdit} note={patient.note} hasOperationsErrors={hasOperationsErrors} />
                </Collapse.Panel>
            </Collapse>

            <CustomModal title={i18n.t('patient.edit')} open={editModalVisible}>
                <EditPatient patient={patient} closeModal={() => setEditModalVisible(false)} focusInput={focusInput} />
            </CustomModal>
        </div>
    )
}

type UserDataTabProps = {
    patientOverview: PatientOverviewType
}

export default UserDataTab
