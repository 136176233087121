import React, {Component, ErrorInfo, PropsWithChildren, PropsWithRef} from 'react'
import {i18n} from '../../i18n'
import {Button, Result} from 'antd'

/**
 * The component used as a wrapper for the App component.
 * Will be used for catching and handling received exceptions.
 */
type ErrorBoundaryComponentProps = {
    children: any
}

class ErrorBoundary extends Component<PropsWithRef<PropsWithChildren<ErrorBoundaryComponentProps>>> {
    state = {error: false, errorMessage: ''}

    static getDerivedStateFromError(error: any) {
        // Update state to render the fallback UI
        return {error: true, errorMessage: error.toString()}
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // Log error to an error reporting service like Sentry
        console.log({error, errorInfo})
    }

    render() {
        const {error, errorMessage} = this.state
        const {children} = this.props

        if (error) {
            return (
                <Result
                    status='500'
                    title={i18n.t('common.errors.general')}
                    extra={
                        <Button type='primary' onClick={() => window.location.reload()}>
                            {i18n.t('button.refresh')}
                        </Button>
                    }
                    subTitle={errorMessage}
                />
            )
        }

        return children
    }
}

export default ErrorBoundary
