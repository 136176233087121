import React from 'react'
import {
    InsuranceStatus,
    InsuranceType,
    OtherMedGroup,
    TherapyForm,
    TherapyIntention,
    TherapyInterruptReason,
    TherapyMedicationGroup,
    TherapyStatus,
    VitalParameterType
} from './enums'

export type ConfigType = {
    dummy: string
}


export interface LocalStyleSheet {
    [key: string]: React.CSSProperties;
}

// To have intellij recognize the import
export type OptionItem = any
export type Options = any

export type PatientSearchEntry = {
    id: string,
    name: string
}

export type DoctorSearchEntry = {
    id: string
    name: string
}

export type ICD10SearchEntry = {
    code: string,
    titleOfficial: string,
    medipolisName: string
}

export type IngredientSearchEntry = {
    id: string,
    name: string,
    note?: string // used to prefill medication notes
    rang?: number
}

export type MedicationSearchEntry = {
    id: string				// medication id
    name: string,
    dosage: number | null,
    unit?: string,
    pzn?: string,
    companyName?: string,
    ingredients: IngredientSearchEntry[]
    // UI
    originallyHadUnit?: boolean,
    originallyHadDosage?: boolean,
    uiDosage?: string
}

export type GenericSearch =
    PatientSearchEntry
    | DoctorSearchEntry
    | ICD10SearchEntry
    | IngredientSearchEntry
    | MedicationSearchEntry;

export type PatientTherapyRowDto = {
    patientId: string,
    patientName: string
    birthday: Date
    insuranceNo: string
    therapyName: string
    therapyId: string /*maybe need to know which one to preselect when go patient view*/
    cycle: number,
    day: number,
    warningMessage?: string
}

export type PatientSectionType = {
    firstName: string,
    lastName: string,
    birthday: Date,
    insuranceNumber: string,
    phone: string,
    email: string,
    note: string,
}

export type TherapySectionType = {
    id: string
    therapyName: string,
    cycleNumber: number,
    intention: TherapyIntention,
    therapyForm: TherapyForm,
    type: string,
    startDate: Date,
    status: TherapyStatus,
    consentGiven: boolean,
    reason?: TherapyInterruptReason
}

export type DiagnosisType = {
    id: string,
    patientId: string,
    icdCode: string,
    titleOfficial: string,
    medipolisName: string,
    title: string
}

export type TherapyGeneralInfo = {
    id: string,
    patientId: string,
    status: TherapyStatus,
    therapyName: string,
    therapyForm: TherapyForm,
    intention: TherapyIntention,
    useOriginalDose: boolean,
    consentGiven: boolean,
    cycleNumber: number,
    cycleDuration: number,
    startDate: Date,
    startDateString: string,
    comment: string,				// therapy comment
    doctor: DoctorSearchEntry,
    dateOfEntry: Date,				// referring to patient medical info: height weight etc
    dateOfEntryString: string
    weight: number,
    height: number,
    bodySurface: number,
}

export type TherapyPrescriptionDto = {
    id: string
    group: TherapyMedicationGroup,
    ingredients: IngredientSearchEntry[]
    therapyMedicationList: TherapyMedicationDto[]
    days: string,
    type: string,
    baseDose?: string,
    dailyDose: number,
    originalDose: number,
    doseModification: number,
    unit: string,
    autIdem: boolean,
    note: string
}

export type TherapyMedicationDto = {
    timeDoses: TimeDosage[]
    originallyHadUnit: boolean,
    originallyHadDosage: boolean
} & MedicationDto

export type MedicationDto = {
    id: string
    name: string
    pzn: string
    dosage: number | null
    unit: string
    ingredients: IngredientSearchEntry[]
}

export type TimeDosage = {
    time: string
    multiplier: number
}

export type RequestedPrescriptionDto = {
    id: string
    ingredients: IngredientSearchEntry[],
    baseDose?: string
    singleDose: number,
    unit: string,
    autIdem: boolean,
    note: string
    productName: string,
    pzn: string,
}

export type OtherPrescriptionDto = {
    id: string,
    therapyId: string,
    group: OtherMedGroup,
    name: string,
    pzn: string,
    note: string,
    prescribedBy: string,
    createdBy: string
}

export type AddressInfo = {
    country: string
    street: string
    postalCode: string
    city: string
    state: string
}

export type InsuranceInfo = {
    name: string
    number: string
    status: InsuranceStatus
    type: InsuranceType
    validFrom: Date
    validTo: Date
}

export type PatientGeneralInfo = {
    id: string,
    academicTitle: string,
    formOfAddress: string,
    firstName: string,
    lastName: string,
    birthday: Date,
    birthdayString: string,
    gender: string,
    address: AddressInfo
    phone: string
    cellphone: string
    email?: string
    insurance: InsuranceInfo
    note: string,
    digitalSupport?: boolean
    patientDataIds: AnyObj,
    exportRequired: boolean // if previous export failed, boolean used to show a button to re-trigger the export
}

export type PatientOverviewType = {
    patient: PatientGeneralInfo
    therapies: TherapySectionType[]
    diagnoses: DiagnosisType[]
    hasOperationsErrors: boolean // if a secondary operation has failed => true
}

export type CompleteTherapyDto = {
    generalInfo: TherapyGeneralInfo,
    therapyMedication: TherapyPrescriptionDto[],
    requestedMedication: RequestedPrescriptionDto[],
    otherMedication: OtherPrescriptionDto[]
}

// side effects

export type SideEffectType = {
    id: string;
    name: string;
    parameterType: string;
    // medicationPlanId: string | null;
    isSystem: boolean
    hcpEnabled: boolean;
    // patientEnabled: boolean;
    possibleValues: PossibleValue[];
    threshold?: number // which should be found inside a possibleValue.value
}
export type PossibleValue = {
    label: string;
    value: number;
    active: boolean
}

export type UpdateObservationType = {
    id: string
    isSystem?: boolean
    hcpEnabled: boolean
    alarmThreshold: {
        min?: number,
        max?: number,
    }
}

// vital data

export type VitalDataType = {
    id: string
    name: string
    description: string
    isSystem?: boolean // if parameterType === mandatory
    hcpEnabled: boolean
    parameterType: VitalParameterType
    parameterDefinitions: VitalParameterDefinition[]
}

export type VitalParameterDefinition = {
    max: number
    min: number
    unit: string
    label: string
    isDecimal: boolean
    definitionId: string
    parameterId: string
    minThreshold: string
    maxThreshold: string
    // UI only - removed on
    error: string
    minHasError: boolean
    maxHasError: boolean
}

export type ObservationType = SideEffectType | VitalDataType


// profile
export type ProfileDto = {
    id: string
    firstName: string
    lastName: string
    gender: string
    eMail: string
    // don't have values
    address: AddressInfo
    telephone: string
    mobilePhone: string
    doctor: boolean
}

export type ChangePassDto = {
    oldPassword: string,
    newPassword: string,
    confirmNewPass: string
}

// icd10

export type DiagnoseInternalType = {
    patient: PatientSearchEntry | null,
    icd10: ICD10SearchEntry | null,
    diagnosisName: string
}

// classes

/**
 * This object will be returned when a secondary operation has failed
 * Will contains the entity id and error message.
 */
export class PartialSuccessResponse {
    id: string
    message: string

    constructor(id: string, message: string) {
        this.id = id
        this.message = message
    }
}


// other

export type I18nEnumPrefix = 'therapy.intention' | 'therapy.form' | 'therapyTab.requestedMedication.group'
    | 'therapyTab.therapyMedication.group' | 'therapyTab.otherMedication.group'
    | 'therapyTab.therapyMedication.type' | 'insurance.status' | 'patients.table.filter'
    | 'therapy.interruptReason' | 'insurance.type' | 'patient.address.country'

export type AnyObj = {
    [key: string]: string
}

export type DoubleSelectorType = {
    key: string,
    title: string,
    chosen: boolean
}
