import {Provider} from 'mobx-react'
import React from 'react'
import {rootStore, StoreContextProvider} from './store'
import EntryPoint from './components/core/EntryPoint'

/**
 * Entry Component of the App
 */
const RootApp: React.FC = () => {
    return (
        <Provider appStore={rootStore}>
            <StoreContextProvider>
                <EntryPoint />
            </StoreContextProvider>
        </Provider>
    )
}

export default RootApp
