import React from 'react'
import {i18n} from '../../i18n'
import ProfileViewTab from './components/ProfileViewTab'
import {observer} from 'mobx-react'
import {useStores} from '../../store'

/**
 * User Profile Page containing ProfileViewTab.
 */
const ProfilePage: React.FC = observer(() => {
    const {
        userStore: {userProfile}
    } = useStores()

    if (!userProfile) {
        return <p>{i18n.t('common.errors.noData')}</p>
    }
    return (
        <div style={{}}>
            <ProfileViewTab userProfile={userProfile} />
        </div>
    )
})

export default ProfilePage
