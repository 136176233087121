import {
    AddressInfo,
    ChangePassDto,
    DiagnoseInternalType,
    InsuranceInfo,
    OtherPrescriptionDto,
    PatientGeneralInfo,
    ProfileDto,
    RequestedPrescriptionDto,
    TherapyGeneralInfo,
    TherapyPrescriptionDto
} from '../../../../utils/types'

export enum EditInsuranceKey {
    name = 'name',
    number = 'number',
    status = 'status',
    type = 'type',
    validFrom = 'validFrom',
    validTo = 'validTo'
}

// every key from TherapyGeneralInfo object; not very elegant but we need this
export enum TherapyKeys {
    therapyName = 'therapyName',
    therapyForm = 'therapyForm',
    intention = 'intention',
    useOriginalDose = 'useOriginalDose',
    consentGiven = 'consentGiven',
    cycleNumber = 'cycleNumber',
    cycleDuration = 'cycleDuration',
    startDate = 'startDate',
    comment = 'comment', // therapy comment
    doctor = 'doctor',
    dateOfEntry = 'dateOfEntry', // referring to patient medical info: height weight etc
    weight = 'weight',
    height = 'height',
    bodySurface = 'bodySurface'
}

export enum EditMedKeys {
    group = 'group',
    ingredients = 'ingredients',
    baseDose = 'baseDose',
    singleDose = 'singleDose',
    dailyDose = 'dailyDose',
    originalDose = 'originalDose',
    doseModification = 'doseModification',
    unit = 'unit',
    type = 'type',
    days = 'days',
    medication = 'medication',
    autIdem = 'autIdem',
    note = 'note',
    bodySurface = 'bodySurface',
    prescribedBy = 'prescribedBy',
    name = 'name',
    timeDoses = 'timeDoses',
    productName = 'productName',
    pzn = 'pzn'
}

export enum EditClientKey {
    academicTitle = 'academicTitle',
    formOfAddress = 'formOfAddress',
    firstName = 'firstName',
    lastName = 'lastName',
    birthday = 'birthday',
    gender = 'gender',
    address = 'address',
    phone = 'phone',
    cellphone = 'cellphone',
    email = 'email',
    insurance = 'insurance',
    note = 'note',
    digitalSupport = 'digitalSupport'
}

export enum EditAddressKey {
    country = 'country',
    street = 'street',
    postalCode = 'postalCode',
    city = 'city',
    state = 'state'
}

export enum EditProfileKey {
    firstName = 'firstName',
    lastName = 'lastName',
    gender = 'gender',
    eMail = 'eMail',
    telephone = 'telephone',
    mobilePhone = 'mobilePhone'
}

export enum ChangePassKey {
    oldPassword = 'oldPassword',
    newPassword = 'newPassword',
    confirmNewPass = 'confirmNewPass'
}

export enum DiagnoseInternalTypeKeys {
    patient = 'patient',
    icd10 = 'icd10',
    diagnosisName = 'diagnosisName'
}

export type ClientKeys = EditClientKey | EditInsuranceKey | EditAddressKey

// define mandatory fields for therapy
export const therapyMandatoryFields: (keyof TherapyGeneralInfo & string)[] = [
    TherapyKeys.therapyName,
    TherapyKeys.intention,
    TherapyKeys.therapyForm,
    TherapyKeys.startDate,
    TherapyKeys.cycleDuration,
    TherapyKeys.doctor,
    TherapyKeys.cycleNumber,
    TherapyKeys.dateOfEntry
]

// define mandatory fields for client
export const clientMandatoryFields: (keyof PatientGeneralInfo & string)[] = [
    EditClientKey.firstName,
    EditClientKey.lastName,
    EditClientKey.birthday,
    EditClientKey.gender
]

export const insuranceMandatoryFields: (keyof InsuranceInfo & string)[] = [EditInsuranceKey.number]

export const addressMandatoryFields: (keyof AddressInfo & string)[] = [EditAddressKey.street, EditAddressKey.postalCode, EditAddressKey.city]

// define mandatory fields for medication

export const otherMedMandatoryFields: (keyof OtherPrescriptionDto & string)[] = [EditMedKeys.group, EditMedKeys.prescribedBy, EditMedKeys.name]

export const requiredMedMandatoryFields: (keyof RequestedPrescriptionDto & string)[] = [
    EditMedKeys.ingredients,
    EditMedKeys.singleDose,
    EditMedKeys.unit
]

export const therapyMedMandatoryFields: (keyof TherapyPrescriptionDto & string)[] = [EditMedKeys.ingredients, EditMedKeys.days]

// define mandatory fields for profile

export const profileMandatoryFields: (keyof ProfileDto & string)[] = [EditProfileKey.eMail, EditProfileKey.firstName, EditProfileKey.lastName]
export const changePassMandatoryFields: (keyof ChangePassDto & string)[] = [
    ChangePassKey.oldPassword,
    ChangePassKey.newPassword,
    ChangePassKey.confirmNewPass
]

export const diagnoseMandatoryFields: (keyof DiagnoseInternalType & string)[] = [DiagnoseInternalTypeKeys.patient, DiagnoseInternalTypeKeys.diagnosisName]
