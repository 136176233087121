import React from 'react'
import {sharedStyles} from '../../style/shared_styles'
import {Button} from 'antd'
import {ButtonStyle} from '../../style/button'
import {i18n} from '../../i18n'

/**
 * The component responsible for rendering the main buttons in the app. Will be used in all components from where an action need to be made.
 * When say main buttons we refers at: save, cancel, back and delete buttons.
 */
const ActionButtons: React.FC<ActionButtonsProps> = (props: ActionButtonsProps) => {
    return (
        <div style={{display: 'flex'}}>
            <div style={sharedStyles.actionBtnLeft}>
                {props.onBack && (
                    <Button type={'default'} shape={'round'} style={ButtonStyle.primary} onClick={props.onBack}>
                        {i18n.t('button.back')}
                    </Button>
                )}
                {props.onCancel && (
                    <Button type={'default'} shape={'round'} style={ButtonStyle.primary} onClick={props.onCancel}>
                        {i18n.t('button.cancel')}
                    </Button>
                )}
            </div>
            <div style={sharedStyles.actionBtnRight}>
                {props.onSaveAndNew && (
                    <Button
                        type={'default'}
                        shape={'round'}
                        style={ButtonStyle.primary}
                        disabled={props.disabledSaveAndNew}
                        onClick={props.onSaveAndNew}>
                        {i18n.t('button.saveAndNew')}
                    </Button>
                )}
                {props.renderDelete && props.onDelete && (
                    <Button type={'default'} shape={'round'} style={ButtonStyle.primary} loading={props.loadingDelete} onClick={props.onDelete}>
                        {i18n.t('button.deleteMedication')}
                    </Button>
                )}
                {props.onSave && (
                    <Button
                        type={'primary'}
                        shape={'round'}
                        style={ButtonStyle.save}
                        disabled={props.disabledSave}
                        loading={props.loadingSave}
                        onClick={props.onSave}>
                        {i18n.t('button.save')}
                    </Button>
                )}
            </div>
        </div>
    )
}

type ActionButtonsProps = {
    onCancel?: () => void
    onSave?: () => void
    onDelete?: () => void
    onBack?: () => void
    onSaveAndNew?: () => void
    loadingSave?: boolean
    disabledSave?: boolean
    disabledSaveAndNew?: boolean
    renderDelete?: boolean
    loadingDelete?: boolean
}

export default ActionButtons
