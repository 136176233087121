import React, {useEffect, useState} from 'react'
import {observer} from 'mobx-react'
import TableHeader from './components/TableHeader'
import PatientsTable from './components/PatientsTable'
import {useStores} from '../../store'
import {PatientTherapyFilter} from '../../utils/enums'

/**
 * The component used for putting together the patient table component and table header component.
 * From here the get patient call will be made and the result will be displayed in the table.
 */
const Home = observer((props: HomeProps) => {
    const [activeFilter, setActiveFilter] = useState(PatientTherapyFilter.ACTIVE_PATIENTS)
    const [loading, setLoading] = useState(false)

    const {patientTherapyStore} = useStores()

    useEffect(() => {
        getPatients()
    }, [activeFilter])

    const getPatients = async () => {
        setLoading(true)
        await patientTherapyStore.initPatientTherapies(activeFilter)
        setLoading(false)
    }

    const onChangeFilter = (filter: PatientTherapyFilter, force?: boolean) => {
        setActiveFilter(filter)
        if (force) {
            getPatients()
        }
    }

    return (
        <div>
            <TableHeader activeFilter={activeFilter} onChangeFilter={onChangeFilter} newPatient={props.newPatient} />
            <PatientsTable patients={patientTherapyStore.patientTherapies} loading={loading} />
        </div>
    )
})

type HomeProps = {
    newPatient?: boolean
}

export default Home
