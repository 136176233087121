import React, {useEffect, useState} from 'react'
import {styles} from '../styles/TableHeader.style'
import {Button} from 'antd'
import {OptionItem} from '../../../utils/types'
import {AvatarImage, CustomDropdown, CustomModal} from '../../common'
import {ButtonStyle} from '../../../style/button'
import {i18n} from '../../../i18n'
import {PatientTherapyFilter} from '../../../utils/enums'
import EditPatient from '../../patient/actions/EditPatient'
import {buildEnumOptions} from '../../../utils/helper'
import {Routes} from '../../../utils/routes'
import {observer} from 'mobx-react'
import {useStores} from '../../../store'
import {useNavigate} from 'react-router-dom'

/**
 * The custom patient header component.
 * Will contains the patient filtering (by type) and the option to create a new patient entry.
 */
const TableHeader: React.FC<TableHeaderProps> = observer((props: TableHeaderProps) => {
    const navigate = useNavigate()
    const [editModalVisible, setEditModalVisible] = useState(props.newPatient)

    const {therapyStore} = useStores()

    const patientTherapyFilters: OptionItem[] = buildEnumOptions(PatientTherapyFilter, 'patients.table.filter')

    const [activeFilter, setActiveFilter] = useState(patientTherapyFilters.find(filter => filter.value === props.activeFilter))

    useEffect(() => {
        onUpdateFilter()
    }, [activeFilter])

    // navigate to new patient when created
    const createNewPatient = (newPatientId: string) => {
        therapyStore.setSelectedTherapyId(null)
        navigate(`${Routes.PATIENT}/${newPatientId}`)
    }

    const onUpdateFilter = (force?: boolean) => activeFilter && props.onChangeFilter?.(activeFilter.value, force)

    return (
        <div style={styles.wrapper}>
            <div style={styles.statusWrapper}>
                <AvatarImage />
                <CustomDropdown items={patientTherapyFilters} selectedItem={activeFilter} onItemSelect={setActiveFilter} />
            </div>
            <div style={styles.buttonWrapper}>
                <Button type={'default'} shape={'round'} style={ButtonStyle.primary} onClick={() => setEditModalVisible(true)}>
                    {i18n.t('patient.new')}
                </Button>
            </div>
            <CustomModal title={i18n.t('patient.newTitle')} open={editModalVisible}>
                <EditPatient closeModal={() => setEditModalVisible(false)} onSuccess={createNewPatient} />
            </CustomModal>
        </div>
    )
})

type TableHeaderProps = {
    activeFilter: PatientTherapyFilter
    newPatient?: boolean
    onChangeFilter: (filter: PatientTherapyFilter, force?: boolean) => void
}

export default TableHeader
