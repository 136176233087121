export enum EnvType {
    DEV = 'DEV',
    PROD = 'PROD'
}

export enum Format {
    IsoDate = 'yyyy-MM-dd',
    DateFormat = 'dd.MM.yyyy',
    MomentFormat = 'DD.MM.YYYY',
    HistoryDateFormat = 'dd.MMM',
    SimpleTime = 'HH:mm',
    MonthFormat = 'MMMM yyyy',
}

export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+$/
export const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?([^\w\s]|[_])).{8,}$/
export const PHONE_REGEX = /^([+][0-9]{1,3}[ .-])?([(]{1}[0-9]{1,6}[)])?([0-9 .\-/]{3,20})((x|ext|extension)[ ]?[0-9]{1,4})?$/
export const POSTAL_CODE_REGEX = /^[0-9]{5}$/
export const INSURANCE_NUMBER_REGEX = /^[a-zA-Z][0-9]{9}$/

export const MAX_HEIGHT = 300

/**
 * The status code value which is used when the response is a partial success.
 */
export const PARTIAL_SUCCESS_RESPONSE_STATUS_CODE = 255

/**
 * This value is used for checking if the token expires within this timeframe.
 * This value is measured in seconds.
 */
export const TOKEN_MIN_VALIDITY = 50