import React, {useState} from 'react'
import {sharedStyles} from '../../../style/shared_styles'
import {AvatarImage, CustomModal, TitleText} from '../../common'
import DetailsEntry from './subcomponents/DetailsEntry'
import {format} from 'date-fns'
import {Format} from '../../../utils/constants'
import {i18n} from '../../../i18n'
import {observer} from 'mobx-react'
import EditPatient from '../actions/EditPatient'
import {EditFilled} from '@ant-design/icons'
import {PatientGeneralInfo} from '../../../utils/types'

/**
 * Component used to display some general patient information on the PatientPage.
 */
const PatientSection: React.FC<PatientSectionProps> = observer(props => {
    const [editModalVisible, setEditModalVisible] = useState(false)

    const patient = props.patient
    return (
        <div style={sharedStyles.container}>
            <AvatarImage size={65} />
            <div style={{flex: 1}}>
                <div style={sharedStyles.inlineContainer}>
                    <TitleText text={`${patient.lastName}, ${patient.firstName}`} />
                    {/*edit action will be available only if current patient doesn't have secondary operations which have failed...*/}
                    {!props.hasOperationsErrors && <EditFilled style={{fontSize: 35}} onClick={() => setEditModalVisible(true)} />}
                </div>
                <div style={{...sharedStyles.inlineContainer, width: '80%'}}>
                    <DetailsEntry
                        title={i18n.t('patient.birthday')}
                        value={patient.birthday ? format(patient.birthday, Format.DateFormat) : undefined}
                    />
                    <DetailsEntry title={i18n.t('patient.insuranceNumber')} value={patient.insurance.number} />
                    <DetailsEntry title={i18n.t('patient.phone')} value={patient.phone} />
                    <DetailsEntry title={i18n.t('patient.email')} value={patient.digitalSupport ? patient.email : undefined} />
                    <DetailsEntry title={i18n.t('patient.note')} value={patient.note} />
                </div>
            </div>

            <CustomModal title={i18n.t('patient.edit')} open={editModalVisible}>
                <EditPatient patient={patient} closeModal={() => setEditModalVisible(false)} />
            </CustomModal>
        </div>
    )
})

type PatientSectionProps = {
    patient: PatientGeneralInfo
    hasOperationsErrors?: boolean | undefined | null
}

export default PatientSection
