import React, {useCallback, useReducer} from 'react'
import {
    formReducer,
    FormStateAction,
    FormStateInterface,
    ObservationActions
} from '../../components/patient/actions/utils/reducer'
import {i18n} from '../../i18n'
import {isBoolean, isNumber, isValidDate} from '../helper'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'

export const useForm = <T>(
    initialValue?: T,
    mandatoryFields?: (keyof T & string)[]
): [
    FormStateInterface<Partial<T>>,
    (key: keyof T & string) => {
        value: any
        error: string
        mandatory: boolean,
        onValueChanged: (val: any, error?: string) => void
    },
    () => boolean,
    (value: any, id: string, error?: string) => void,
] => {
    const [formState, dispatchFormState] = useReducer<React.Reducer<FormStateInterface<Partial<T>>, FormStateAction<Partial<T>>>>(formReducer, {
        values: initialValue || {},
        errors: {} as any,
        formIsValid: true
    })

    const inputChangeHandler = useCallback(
        (value: any, id: string, error?: string) => {
            dispatchFormState({
                type: ObservationActions.FORM_INPUT_UPDATE,
                value,
                id,
                error
            })
        },
        [dispatchFormState]
    )

    const extractProps = useCallback(
        (key: keyof T & string) => {
            let value = formState.values[key] as any;
            const isDateValue = isValidDate(value);
            value = !isDateValue && isNumber(value) && !Number.isInteger(value) && !isBoolean(value) ? (value as number).toLocaleString(i18n.locale) : value
            return {
                id: key,
                value: value,
                error: formState.errors[key],
                mandatory: isFieldMandatory(key),
                onValueChanged: (val: any, error?: string) => {
                    inputChangeHandler(val, key, error)
                }
            }
        },
        [formState, mandatoryFields]
    )

    const onValidateInputs = (): boolean => {
        let isValid = true;

        mandatoryFields?.forEach(field => {
            const value = formState.values[field]
             if(!value || (Array.isArray(value) && value.length === 0) || (isString(value) && isEmpty(value.trim()))){
                inputChangeHandler(value, field, i18n.t('common.errors.empty'))
                isValid = false
            }
        })
        return isValid && formState.formIsValid;
    }

    const isFieldMandatory = (key: keyof T & string) => {
        if (!mandatoryFields) {
            return false;
        }
        return mandatoryFields.includes(key);
    }

    return [formState, extractProps, onValidateInputs, inputChangeHandler]
}
