import React, {useEffect, useState} from 'react'
import App from './App'
import Keycloak from 'keycloak-js'
import {Button, Result, Spin} from 'antd'
import {observer} from 'mobx-react'
import {useStores} from '../../store'
import './styles/ant_overrides.css'
import {i18n} from '../../i18n'
import {ErrorBoundary} from '../common'

/**
 * The entry point of the application. Here will be checked the user permissions.
 * It is used as an App wrapper component.
 */
const EntryPoint: React.FC = observer(() => {
    const [failedInitKeycloak, setFailedInitKeycloak] = useState(false)

    const {userStore} = useStores()

    useEffect(() => {
        const keycloak = new Keycloak('/keycloak')
        keycloak
            .init({onLoad: 'login-required', checkLoginIframe: false})
            .then(() => {
                userStore.setKeycloak(keycloak)
            })
            .catch(e => {
                console.error(e)
                setFailedInitKeycloak(true)
            })
    }, [])

    if (failedInitKeycloak || userStore.userConfigs === undefined) {
        return (
            <Result
                status='500'
                title={i18n.t('common.errors.general')}
                subTitle={failedInitKeycloak ? i18n.t('common.errors.keycloak') : i18n.t('common.errors.configs')}
                extra={
                    <Button type='primary' onClick={() => window.location.reload()}>
                        {i18n.t('button.refresh')}
                    </Button>
                }
            />
        )
    }

    if (!userStore.keycloak || userStore.userConfigs === null) {
        return (
            <div style={{display: 'flex', justifyContent: 'center', marginTop: '25%'}}>
                <Spin size={'large'} />
            </div>
        )
    }

    return (
        <ErrorBoundary>
            <App />
        </ErrorBoundary>
    )
})

export default EntryPoint
