import {AxiosRequestHeaders, AxiosResponse} from 'axios'
import {IPatientService} from './utils/types'
import {ChangePassDto, PatientGeneralInfo, PatientOverviewType, ProfileDto} from '../utils/types'
import BaseService from './base_service'
import {useAuthHeader} from '../utils/hooks/useAuthHeader'

class PatientService extends BaseService implements IPatientService {
    async getPatient(id: string): Promise<AxiosResponse<PatientOverviewType>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.client.get(`/api/private/patient/${id}/overview`, {
            headers: {
                ...authHeader
            }
        })
    }

    async createPatient(patient: PatientGeneralInfo): Promise<AxiosResponse<string>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.client.post('/api/private/patient/', patient, {
            headers: {
                ...authHeader
            }
        })
    }

    async updatePatient(patient: PatientGeneralInfo): Promise<AxiosResponse<string>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.client.put(`/api/private/patient/${patient.id}`, patient, {
            headers: {
                ...authHeader
            }
        })
    }

    async getUserConfigs(): Promise<AxiosResponse<Record<string, any>>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.client.get(`/api/private/config/`, {
            headers: {
                ...authHeader
            }
        })
    }

    async getUserProfile(): Promise<AxiosResponse<ProfileDto>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.client.get(`/api/private/profile/`,{
            headers: {
                ...authHeader
            }
        })
    }

    async updateProfile(profile: ProfileDto): Promise<AxiosResponse<void>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.client.patch(`/api/private/profile/`, profile,{
            headers: {
                ...authHeader
            }
        })
    }

    async changePassword(changePass: ChangePassDto): Promise<AxiosResponse<void>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.client.put(`/api/private/profile/password`, changePass,{
            headers: {
                ...authHeader
            }
        })
    }
}

export default PatientService
