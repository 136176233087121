import React, {useState} from 'react'
import {CustomModal, TitleText} from '../../../common'
import {i18n} from '../../../../i18n'
import {Button, Table} from 'antd'
import {RequestedPrescriptionDto} from '../../../../utils/types'
import {getIngredientNames, getRequestedMedicationColumns} from '../../../../utils/medication_helper'
import {sharedStyles} from '../../../../style/shared_styles'
import {ButtonStyle} from '../../../../style/button'
import EditRequestedMed from '../../actions/EditRequestedMed'
import {useStores} from '../../../../store'
import {ConfigTypes} from '../../../../utils/enums'
import {observer} from 'mobx-react'

/**
 * Component displaying the requested medication data of a therapy plan.
 */
const RequestedMedicationTable: React.FC<RequestedMedicationTableProps> = observer(props => {
    const [selectedMedId, setSelectedMedId] = useState('')
    const [modalVisible, setModalVisible] = useState(false)
    const {userStore} = useStores()
    const dataSource = props.meds.map(item => ({
        key: item.id,
        dosage: `${item.singleDose?.toLocaleString(i18n.locale)} ${userStore.getI18nText(ConfigTypes.UNIT_MEASURE, item.unit)}`,
        ingredientName: getIngredientNames(item.ingredients),
        ...item
    }))

    const onEditRow = (id: string) => {
        setSelectedMedId(id)
        setModalVisible(true)
    }

    const onNewItem = () => {
        setSelectedMedId('')
        setModalVisible(true)
    }

    return (
        <div>
            <div style={{...sharedStyles.inlineContainer, marginTop: '2%'}}>
                <TitleText text={i18n.t('therapyTab.requestedMedication.title')} />
                {props.actionsAvailable && (
                    <Button type={'default'} shape={'round'} style={ButtonStyle.primary} onClick={onNewItem}>
                        {i18n.t('button.create')}
                    </Button>
                )}
            </div>
            <Table
                dataSource={dataSource}
                columns={getRequestedMedicationColumns(props.actionsAvailable && onEditRow)}
                pagination={false}
                rowClassName={'disable-hover'}
            />
            <CustomModal title={i18n.t(`editMed.title.required.${selectedMedId ? 'edit' : 'new'}`)} open={modalVisible} width={'50%'}>
                <EditRequestedMed requestedMed={props.meds.find(med => med.id === selectedMedId)} onCloseModal={() => setModalVisible(false)} />
            </CustomModal>
        </div>
    )
})

type RequestedMedicationTableProps = {
    meds: RequestedPrescriptionDto[],
    actionsAvailable: boolean
}

export default RequestedMedicationTable
