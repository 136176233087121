import {LocalStyleSheet} from '../../../utils/types'

export const styles: LocalStyleSheet = {
    navSearchWrapper: {
        display: 'flex',
        width: '100%',
        alignItems: 'center'
    },
    navSearch: {
        width: '100%'
    },
    navSearchIcon: {
        paddingRight: 10,
        paddingLeft: 10,
        color: 'inherit'
    },
    header: {
        paddingLeft: 92,
        height: 75,
        paddingRight: 62,
        display: 'flex'
    },
    menu: {
        paddingTop: 5,
        width: '100%',
        display: 'flex',
        alignItems: 'center'
    },
    menuItemNavSearch: {
        background: 'inherit',
        backgroundColor: 'inherit',
        cursor: 'inherit',
        width: '45%'
    },
    marginRightAuto: {
        marginRight: 'auto'
    },
    logo: {
        paddingTop: 13,
        marginRight: 11
    }
}
