import React, {useEffect, useMemo, useState} from 'react'
import {i18n} from '../../../i18n'
import {sharedStyles} from '../../../style/shared_styles'
import DetailsEntry from './subcomponents/DetailsEntry'
import {OptionItem, TherapySectionType} from '../../../utils/types'
import {styles} from '../styles/TherapySection.style'
import {fromJson, toJson} from '../../../utils/helper'
import {CustomSelect} from '../../common'
import {useTherapyDetails} from '../../common/hooks/useTherapyDetails'
import {observer} from 'mobx-react'
import {useStores} from '../../../store'
import {usePrevious} from '../../../utils/hooks'

/**
 * Component used to select a patient's therapy. Also displays therapy general data.
 */
const TherapySection: React.FC<TherapySectionProps> = observer(props => {
    const [therapyPlan, setTherapyPlan] = useState<TherapySectionType | undefined>()
    const {currentDayIndex} = useTherapyDetails()
    const {therapyStore} = useStores()
    const prevProps = usePrevious(props)

    const onChange = (therapyJson: string) => {
        const selectedTherapy = fromJson(therapyJson)
        setTherapyPlan(selectedTherapy)
        therapyStore.setSelectedTherapyId(selectedTherapy.id)
    }

    useEffect(() => {
        // get all data about therapy only when the therapy from dropdown has been changed or status was updated
        if (
            !therapyPlan ||
            (therapyPlan.id === therapyStore.therapyComplete?.generalInfo.id &&
                therapyPlan.status === therapyStore.therapyComplete.generalInfo.status)
        ) {
            return
        }
        const getTherapyById = async () => {
            await therapyStore.getTherapyById(therapyPlan.id, true)
        }
        getTherapyById()
    }, [therapyPlan])

    useEffect(() => {
        if (toJson(props.patientTherapies) === toJson(prevProps?.patientTherapies)) {
            // because of the shallow refresh of the user, the list reference might change while its content is identical, so we don't need to do anything again
            return
        }
        // Refresh the displayed therapy plan in case there are modifications to then inside the store
        if (props.patientTherapies) {
            // on mount therapyPlan is null and store's selectedTherapyId is used if necessary
            const alreadySetId = therapyStore.selectedTherapyId || therapyPlan?.id
            const prefilledTherapy = props.patientTherapies.find(therapy => therapy.id === alreadySetId)
            const prefillCondition = alreadySetId && prefilledTherapy
            setTherapyPlan(prefillCondition ? prefilledTherapy : props.patientTherapies?.[0])
        }
    }, [props.patientTherapies])

    const selectOptions: OptionItem[] = useMemo(() => {
        return props.patientTherapies
            .map((therapy: TherapySectionType) => {
                const {status, reason} = therapy
                const displayStatus = i18n.t(reason ? `therapy.interruptReason.${reason}` : `therapy.status.${status}`)
                const therapyName = therapy.therapyName
                return {
                    key: therapy.id,
                    label: `${i18n.t('therapy.label.cycle')} ${therapy.cycleNumber}: ${therapyName.substr(0, 20)} ${displayStatus}`,
                    value: toJson(therapy),
                    cycleNumber: therapy.cycleNumber
                }
            })
            .sort((a, b) => (a.cycleNumber < b.cycleNumber ? -1 : 1))
    }, [props.patientTherapies])

    const intention = therapyPlan?.intention ? i18n.t(`therapy.intention.${therapyPlan?.intention}`) : undefined
    const therapyForm = therapyPlan?.therapyForm ? i18n.t(`therapy.form.${therapyPlan?.therapyForm}`) : undefined
    return (
        <div style={sharedStyles.sectionWrapper}>
            <h3 style={styles.padding}>{i18n.t('therapy.selectPlan')}</h3>
            <CustomSelect items={selectOptions} onValueChanged={onChange} value={toJson(therapyPlan)} enableSearch={true}/>
            <div style={{...sharedStyles.inlineContainer, ...styles.padding, paddingTop: 10}}>
                <DetailsEntry title={i18n.t('therapy.label.cycle')} value={therapyPlan?.cycleNumber} />
                <DetailsEntry title={i18n.t('therapy.label.day')} value={currentDayIndex !== -1 ? currentDayIndex : undefined} />
                <DetailsEntry title={i18n.t('therapy.label.intention')} value={intention} />
                <DetailsEntry title={i18n.t('therapy.label.form')} value={therapyForm} />
            </div>
        </div>
    )
})

type TherapySectionProps = {
    patientTherapies: TherapySectionType[]
    prefillTherapyId?: string
}

export default TherapySection
