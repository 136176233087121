import React, { useEffect, useState } from "react";
import { styles as boxStyles } from "../../styles/Box.style";
import { generateNumberArray } from "../../../../utils/helper";
import Box from "./Box";
import { getDaysArray, getDaysString } from "../../../../utils/medication_helper";
import { useTherapyDetails } from "../../../common/hooks/useTherapyDetails";

/**
 * Component used to display medication diagrams using boxes
 */
const BoxDiagram: React.FC<BoxDiagramProps> = (props) => {
    const {nrWeeks, excludedDays, currentDayIndex, dayOfWeek, duration} = useTherapyDetails()

    const [daysToTake, setDaysToTake] = useState<number[]>([])

    useEffect(() => {
        const [daysArray, error] = getDaysArray(props.daysString || '', duration)
        if (error) {
            props.setDaysString?.(props.daysString || '', error)
            return
        }
        setDaysToTake(daysArray)
    }, [props.daysString])

    const onBoxClick = (idx: number) => {
        if (idx > duration) {
            return
        }
        let newDaysToTake
        if (daysToTake.includes(idx)) {
            newDaysToTake = daysToTake.filter(day => day !== idx)
        } else {
            daysToTake.push(idx)
            newDaysToTake = [...daysToTake]
        }
        props.setDaysString?.(getDaysString(newDaysToTake))
    }

    return (
        <>
            {nrWeeks.map(weekNbr => {
                const daysInWeek = generateNumberArray(7)
                return (
                    <div key={`${props.id}${weekNbr}`} style={boxStyles.boxWrapper}>
                        {daysInWeek.map(dayNbr => {
                            const currentSquareIndex = weekNbr * 7 + dayNbr + 1
                            const isIntakeDay = daysToTake.includes(currentSquareIndex)
                            const isExcludedDay = excludedDays.includes(currentSquareIndex)
                            const firstDay = currentSquareIndex === 1
                            const displayNumber = props.isView ? '' : currentSquareIndex
                            const displayDay = firstDay ? `(${dayOfWeek})` : ''
                            return (
                                <Box key={`${props.id}${weekNbr}${dayNbr}`}
                                     displayText={displayNumber + displayDay}
                                     displayTextCondition={props.isView ? firstDay : true}
                                     isIntake={isIntakeDay}
                                     isExcluded={isExcludedDay}
                                     isCurrentDay={!!props.isView && currentDayIndex === currentSquareIndex}
                                     onClick={() => !props.isView && onBoxClick(currentSquareIndex)}/>
                            )
                        })}
                    </div>
                )
            })}
        </>
    )
}

type BoxDiagramProps = {
    id: string
    daysString: string | undefined,
    setDaysString?: (value: string, error?: string) => void
    isView?: boolean // controls the displayed text
}

export default BoxDiagram
