import React, {useState} from 'react'
import {observer} from 'mobx-react'
import {i18n} from '../../../i18n'
import {sharedStyles} from '../../../style/shared_styles'
import {CustomModal, TitleText} from '../../common'
import {Button, Divider} from 'antd'
import EditDetailsEntry from '../../patient/components/subcomponents/EditDetailsEntry'
import {formatAddress} from '../../../utils/uiHelper'
import {ProfileDto} from '../../../utils/types'
import ChangePassword from './ChangePassword'

/**
 * Component used to display user data.
 */
const ProfileViewTab = observer((props: ProfileViewTabProps) => {
    const [changePassVisible, setChangePassVisible] = useState(false)

    const fullName = props.userProfile.firstName + ' ' + props.userProfile.lastName

    return (
        <div>
            <div style={{width: '98%', marginRight: 10}}>
                <div style={sharedStyles.inlineContainer}>
                    <TitleText size={20} text={i18n.t('profile.title')} />
                    {/*Edit Profile Button*/}
                    {/*<EditFilled style={{fontSize: 30}} onClick={() => setEditModalVisible(true)} />*/}
                </div>
                <Divider />
            </div>
            <div style={{display: 'flex'}}>
                <div style={sharedStyles.leftColumn}>
                    <EditDetailsEntry title={i18n.t('userDataTab.general.name')} value={fullName} />
                    <EditDetailsEntry
                        title={i18n.t('userDataTab.contact.email.title')}
                        value={<a href={`mailto:${props.userProfile.eMail}`}>{props.userProfile.eMail}</a>}
                    />
                    <EditDetailsEntry
                        title={i18n.t('profile.edit.password')}
                        value={
                            <Button type={'link'} size={'small'} style={{paddingLeft: 0}} onClick={() => setChangePassVisible(true)}>
                                {i18n.t('profile.edit.changePass')}
                            </Button>
                        }
                    />

                    <EditDetailsEntry
                        title={i18n.t('userDataTab.general.role')}
                        value={i18n.t(`userDataTab.role.${props.userProfile.doctor ? 'doctor' : 'nurse'}`)}
                    />
                </div>

                <div style={sharedStyles.rightColumn}>
                    <EditDetailsEntry title={i18n.t('userDataTab.contact.phone')} value={props.userProfile.telephone} />
                    <EditDetailsEntry title={i18n.t('userDataTab.contact.cellphone')} value={props.userProfile.mobilePhone} />
                    <EditDetailsEntry title={i18n.t('userDataTab.contact.address')} value={formatAddress(props.userProfile.address)} />
                </div>
            </div>
            {/*Edit profile Modal*/}
            {/*<CustomModal title={i18n.t('profile.edit.title')} visible={editModalVisible}>*/}
            {/*    <EditGeneralProfile closeModal={() => setEditModalVisible(false)} profile={props.userProfile} />*/}
            {/*</CustomModal>*/}
            <CustomModal title={i18n.t('profile.changePass.title')} open={changePassVisible}>
                <ChangePassword closeModal={() => setChangePassVisible(false)} />
            </CustomModal>
        </div>
    )
})

type ProfileViewTabProps = {
    userProfile: ProfileDto
}

export default ProfileViewTab
