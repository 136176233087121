import React, {useState} from 'react'
import {VitalDataType, VitalParameterDefinition} from '../../../../utils/types'
import {Switch, Typography} from 'antd'
import {sharedStyles} from '../../../../style/shared_styles'
import {ErrorField, Input} from '../../../common'
import {i18n} from '../../../../i18n'

/**
 * Component representing a vital data entry. Used by the ObservationDisplay component.
 */
const VitalDataEntry: React.FC<VitalDataEntryProps> = props => {
    const [errors] = useState<Map<string, string>>(new Map())
    const vd = props.vitalData
    const printLabel = vd.parameterDefinitions.length > 1 // because the labels in case of single definitions are retarded
    const onValueChanged = (param: VitalParameterDefinition, val: any, error: string | undefined, isMin: boolean) => {
        const idSufix = isMin ? 'min' : 'max'
        errors.set(param.parameterId + idSufix, error || '')
        if (errors.get(param.parameterId + idSufix) === '' && !error) {
            errors.delete(param.parameterId + idSufix)
        }
        if(isMin) {
            param.minHasError = !!error
        } else {
            param.maxHasError = !!error
        }
        props.onValueChanged?.(param, val, isMin? 'minThreshold' : 'maxThreshold')
    }
    
    return (
        <div style={{marginTop: 10}}>
            <div style={sharedStyles.inlineContainer}>
                <Typography.Title keyboard={true} level={4}>
                    {vd.name}
                </Typography.Title>
                <Switch onChange={props.onSwitchChange} disabled={props.disabled} style={{marginLeft: 10}}
                        checked={vd.hcpEnabled}/>
            </div>
            {vd.parameterDefinitions.map((param: VitalParameterDefinition) => (
                <div key={param.parameterId}>
                    {printLabel && <span style={{fontSize: 20}}>{param.label}</span>}
                    <div style={{display: 'flex'}}>
                        <div style={sharedStyles.leftColumn}>
                            <Input
                                label={i18n.t('therapy.verify.vitalData.min')}
                                placeholder={param.unit}
                                disabled={props.disabled}
                                onValueChanged={(val, error) => {
                                    onValueChanged(param, val, error, true)
                                }}
                                error={errors.get(param.parameterId + 'min')}
                                min={Number(param.min)}
                                max={Number(param.max)}
                                value={param.minThreshold}
                                type={'number'}
                                isInteger={!param.isDecimal}
                            />
                        </div>
                        <div style={sharedStyles.rightColumn}>
                            <Input
                                label={i18n.t('therapy.verify.vitalData.max')}
                                placeholder={param.unit}
                                disabled={props.disabled}
                                onValueChanged={(val, error) => {
                                    onValueChanged(param, val, error, false)
                                }}
                                error={errors.get(param.parameterId + 'max')}
                                min={Number(param.min)}
                                max={Number(param.max)}
                                value={param.maxThreshold}
                                type={'number'}
                                isInteger={!param.isDecimal}
                            />
                        </div>
                    </div>
                    <ErrorField error={param.error} hideErrorField={!param.error}/>
                </div>
            ))}
        </div>
    )
}

type VitalDataEntryProps = {
    vitalData: VitalDataType
    onSwitchChange?: () => void
    onValueChanged?: (param: VitalParameterDefinition, value: string, type: 'minThreshold' | 'maxThreshold') => void
    disabled?: boolean
}

export default VitalDataEntry
