//~~~ Therapy enums ~~~

export enum TherapyIntention {
    OTHER = 'OTHER',
    ADJUVANT = 'ADJUVANT',
    CURATIVE = 'CURATIVE',
    PALLIATIVE = 'PALLIATIVE'
}

export enum TherapyForm {
    OTHER = 'OTHER',
    FIRST_LINE = 'FIRST_LINE',
    SECOND_LINE = 'SECOND_LINE',
    THIRD_LINE = 'THIRD_LINE',
    FOURTH_LINE = 'FOURTH_LINE',
    FIFTH_LINE = 'FIFTH_LINE',
    SALVAGE = 'SALVAGE',
    MAINTENANCE = 'MAINTENANCE'
}

export enum PatientTherapyFilter {
    ACTIVE_PATIENTS = 'ACTIVE_PATIENTS',
    INACTIVE_PATIENTS = 'INACTIVE_PATIENTS',
    TO_BE_EXAMINED_THERAPY = 'TO_BE_EXAMINED_THERAPY',
    APPROVED_THERAPY = 'APPROVED_THERAPY',
    PATIENT_WITHOUT_THERAPY = 'PATIENT_WITHOUT_THERAPY'
}

export enum TherapyStatus {
    PLANNED = 'PLANNED',
    TO_CHECK = 'TO_CHECK',
    RELEASED = 'RELEASED',
    CLOSED = 'CLOSED'
}

export enum TherapyInterruptReason {
    CLOSED = 'CLOSED',
    CANCELLED = 'CANCELLED',
    DISCARDED = 'DISCARDED',
}

//~~~ Medication enums ~~~

export enum IntakeType {
    PO = 'PO',
    IV = 'IV',
    STRENG_IV = 'STRENG_IV',
    BUCC = 'BUCC',
    CUTAN = 'CUTAN',
    IM = 'IM',
    SC = 'SC'
}

export enum TherapyMedicationGroup {
    THERAPY = 'THERAPY',
    SUPPORTIVE = 'SUPPORTIVE'
}

export enum OtherMedGroup {
    FAMILY_DOCTOR = 'FAMILY_DOCTOR',
    OTC = 'OTC'
}


// patient constants
export enum InsuranceStatus {
    PRIVATE = 'PRIVATE',
    EXCEPT_FROM_ADDITIONAL_PAYMENTS = 'EXCEPT_FROM_ADDITIONAL_PAYMENTS',
    SUBJECT_TO_ADDITIONAL_PAYMENTS = 'SUBJECT_TO_ADDITIONAL_PAYMENTS',

}

export enum InsuranceType {
    PRIVATE = 'PRIVATE',
    PUBLIC = 'PUBLIC',
}

// vital data

export enum VitalParameterType {
    HOWDY = 'HOWDY',
    DOCTOR = 'DOCTOR',
    MANDATORY = 'MANDATORY',
    OPTIONAL = 'OPTIONAL',
    CONDITIONAL = 'CONDITIONAL'
}


// configs

export enum ConfigTypes {
    THERAPY_NAME = 'THERAPY_NAME',
    FEDERAL_STATE = 'FEDERAL_STATE',
    THERAPY_TYPES = 'THERAPY_TYPES',
    TITLE = 'TITLE',
    UNIT_MEASURE = 'UNIT_MEASURE',
    FORM_OF_ADDRESS = 'FORM_OF_ADDRESS',
    GENDER = 'GENDER',
    MAX_WEIGHT = 'MAX_WEIGHT',
    TINO_DOC_URL = 'TINO_DOC_URL'
}

// only in JS
export enum SearchTypes {
    patient = 'patient',
    doctor = 'doctor',
    icd10 = 'icd10',
    ingredient = 'ingredient',
    product = 'product'
}

export enum StorageKey {
    therapyId = 'therapyId'
}

// countries enum
export enum Countries {
    Germany = 'Deutschland'
}
