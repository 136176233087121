import React from 'react';
import {i18n} from '../../../../i18n'
import EditDetailsEntry from '../subcomponents/EditDetailsEntry'
import {ClientKeys, EditClientKey} from '../../actions/utils/keys'

/**
 * Component displaying the patient's additional information.
 */
const ExtraInfoPanel: React.FC<ExtraInfoPanelProps> = (props: ExtraInfoPanelProps) => {
    const onEditDetails = (input: ClientKeys) => props.onEdit(input)
    return (
       <div style={{width: '50%'}}>
           <EditDetailsEntry
               title={i18n.t('userDataTab.extraInfo.note.title')}
               value={props.note}
               onEdit={() => onEditDetails(EditClientKey.note)}
               extraInfo={i18n.t('userDataTab.extraInfo.note.extra')}
               hasOperationsErrors={props.hasOperationsErrors}
           />
       </div>
    )
}

type ExtraInfoPanelProps = {
    note: string,
    onEdit: (input: ClientKeys) => void
    hasOperationsErrors?: boolean | undefined | null
}

export default ExtraInfoPanel
