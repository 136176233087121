import React, {useEffect, useState} from 'react'
import {ActionButtons, CustomModal, InputLabel} from '../../../common'
import {i18n} from '../../../../i18n'
import {sharedStyles} from '../../../../style/shared_styles'
import {TherapyInterruptReason, TherapyStatus} from '../../../../utils/enums'
import {useStores} from '../../../../store'
import VSpace from '../../../common/VSpace'
import {observer} from 'mobx-react'
import {Typography} from 'antd'

const decideInterruptReason = (status: TherapyStatus): TherapyInterruptReason => {
    return status === TherapyStatus.RELEASED ? TherapyInterruptReason.CANCELLED : TherapyInterruptReason.DISCARDED
}

/**
 * Component representing the therapy archive modal.
 */
const ArchiveModal: React.FC<ArchiveModalProps> = observer((props) => {
    const [therapyInterruptReason, setTherapyInterruptReason] = useState<TherapyInterruptReason>()
    const {therapyStore} = useStores()

    useEffect(() => {
        setTherapyInterruptReason(decideInterruptReason(props.status))
    }, [props.status])



    const doArchive = async () => {
        await therapyStore.closeTherapy()
        closeModal()
        props.onSave?.()
    }

    const closeModal = () => {
        props.setVisible(false)
    }
    if (!therapyInterruptReason) {
        return <></>
    }

    return (
        <CustomModal open={props.visible} title={i18n.t('therapyTab.basicSection.archive.title')}>
            <div style={{...sharedStyles.column, alignItems: 'center'}}>
                <InputLabel
                    label={i18n.t('therapyTab.basicSection.archive.reason')}
                />
                <Typography.Text keyboard={true} style={{fontSize: 18, marginTop: 10}} >
                    {i18n.t(`therapy.interruptReason.${therapyInterruptReason}`)}
                </Typography.Text>
                <VSpace size={25} />
                <ActionButtons onCancel={closeModal} onSave={doArchive} />
            </div>
        </CustomModal>
    )
})

type ArchiveModalProps = {
    visible: boolean,
    setVisible: (state: boolean) => void,
    status: TherapyStatus
    onSave?: () => void
}

export default ArchiveModal
