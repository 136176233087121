import React, {useState} from 'react'
import {sharedStyles} from '../../../../style/shared_styles'
import {CustomModal, TitleText} from '../../../common'
import {i18n} from '../../../../i18n'
import {Button} from 'antd'
import {ButtonStyle} from '../../../../style/button'
import styles from '../../styles/TherapyTab.style'
import EditTherapy from '../../actions/EditTherapy'

/**
 * Component containing the therapy plan header including "pdf creation" and "new therapy" buttons.
 */
const TherapyPlanHeader: React.FC<MedicationHeaderProps> = (props: MedicationHeaderProps) => {
    const [editModalVisible, setEditModalVisible] = useState(false)

    const pdfButtonVisible: boolean | undefined = props.onCreatePdf && !props.hasOperationsErrors
    return (
        <div>
            <div style={sharedStyles.inlineContainer}>
                <TitleText text={i18n.t('patientView.tabs.therapyPlan')} />
                <div>
                    {pdfButtonVisible && (
                        <Button type={'default'} shape={'round'} style={ButtonStyle.primary} onClick={props.onCreatePdf} loading={props.loadingPdf}>
                            {i18n.t('therapyTab.actions.createPDF')}
                        </Button>
                    )}

                    {!props.hasOperationsErrors && (
                        <Button type={'default'} shape={'round'} style={ButtonStyle.primary} onClick={() => setEditModalVisible(true)}>
                            {i18n.t('therapyTab.actions.newTherapy')}
                        </Button>
                    )}
                </div>
            </div>
            {props.showMessage && (
                <div style={styles.messageContainer}>
                    <p style={styles.messageTitle}>{i18n.t('therapyTab.message.title')}</p>
                    <p>{i18n.t('therapyTab.message.content')}</p>
                </div>
            )}

            <CustomModal title={i18n.t('therapyTab.basicSection.edit.title')} open={editModalVisible} width={'60%'}>
                <EditTherapy onCloseModal={() => setEditModalVisible(false)} />
            </CustomModal>
        </div>
    )
}

type MedicationHeaderProps = {
    onCreatePdf?: () => void
    showMessage?: boolean
    loadingPdf?: boolean
    hasOperationsErrors?: boolean | undefined | null
}

export default TherapyPlanHeader
