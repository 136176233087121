import {LocalStyleSheet} from '../../../utils/types'

export const styles: LocalStyleSheet = {
    tableEntry: {
        display: 'flex',
        flexDirection: 'row',
        width: '50%'
    },
    textSection: {
        display: 'flex',
        flexDirection: 'column'
    },
    subParagraph: {
        paddingRight: '1%'
    },
    image: {
        width: 210
    },
    column1: {border: 1, borderStyle: 'solid', width: '15%'},
    column2: {border: 1, borderStyle: 'solid', paddingLeft: '1%', width: '100%'},
    column2Content: {
        display: 'flex',
        flexDirection: 'column'
    },
    row: {
        border: 1,
        borderStyle: 'solid'
    }
}
