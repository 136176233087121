import {LocalStyleSheet} from "../../../utils/types";

export const styles: LocalStyleSheet = {
    devWrap: {
        position: 'absolute',
        top: '15%',
        right: 0,
    },
    devLang: {
        display: 'flex',
        flexDirection: 'column'
    },
    navRight: {
        float: 'right'
    },
    content: {
        marginLeft: 92,
        marginTop: 20,
        marginRight: 82,
        flex: 1
    }
}
