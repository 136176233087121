import {i18n} from '../i18n'
import {VitalParameterDefinition} from './types'

export const validateVitalData = (vitalParameterDefinition: VitalParameterDefinition, value: string, type: string) => {
    let error = ''
    if (!vitalParameterDefinition.isDecimal && value && !isInteger(value)) {
        error = i18n.t('common.errors.invalidIntegerInput')
    }
    if (vitalParameterDefinition.isDecimal && value && !isDecimal(value)) {
        error = i18n.t('common.errors.invalidDecimalInput')
    }

    if (
        (type === 'minThreshold' && (toNumber(value) || 0) > (toNumber(vitalParameterDefinition.maxThreshold) || 0)) ||
        (type === 'maxThreshold' && (toNumber(value) || 0) < (toNumber(vitalParameterDefinition.minThreshold) || 0))
    ) {
        error = i18n.t('common.errors.invalidRangeAlarm')
    }

    return error
}

/**
 * This method check if the received value is integer based on a regex test
 * @param value
 */
export const isInteger = (value: any): boolean => {
    return /^\d$|^[1-9]\d*$/.test(value)
}

/**
 * Check if a value is decimal
 * @param value
 */
export const isDecimal = (value: any): boolean => {
    return /^\d$|^[1-9]\d*$|^\d[,.]{1}\d+$|^[1-9]\d*[,.]{1}\d+$/.test(value)
}

/**
 * Transforms a string in a number
 * @param value
 */
export const toNumber = (value: string | undefined | number) => {
    if (!value) {
        return undefined
    }
    if (typeof value === 'number') {
        return value
    }
    if (value?.includes(',')) {
        value = value.replace(',', '.')
    }
    const numberVal = Number(value)
    if (isNaN(numberVal)) {
        return undefined // make it clear there's something wrong
    }
    return numberVal
}

/**
 * Splits an array into 2 subarrays, the first containing the elements respecting the given filter, and the second containing the rest
 * @param array
 * @param filter
 */
export const partition = <T>(array: T[], filter: (value: T, index?: number, array?: T[]) => boolean | undefined) => {
    const pass: T[] = []
    const fail: T[] = []
    array.forEach((el, idx, arr) => (filter(el, idx, arr) ? pass : fail).push(el))
    return [pass, fail]
}
