import React from 'react'
import {PatientTherapyRowDto} from '../../../utils/types'
import {Table} from 'antd'
import {i18n} from '../../../i18n'
import PatientDetailsCell from './PatientDetailsCell'
import {Routes} from '../../../utils/routes'
import {useNavigate} from 'react-router-dom'
import {useStores} from '../../../store'

/**
 * The component used for rendering the custom patient table.
 */
const PatientsTable: React.FC<PatientsTableProps> = (props: PatientsTableProps) => {
    const navigate = useNavigate()
    const {therapyStore} = useStores()

    const dataSource: ColumnType[] = props.patients.map(patient => {
        return {
            key: patient.patientId + patient.therapyId,
            patientId: patient.patientId,
            name: <PatientDetailsCell patient={patient} />,
            nameHidden: patient.patientName,
            therapy: patient.therapyName,
            therapyId: patient.therapyId,
            cycle: patient.cycle,
            day: patient.day
        }
    })

    const columns = [
        {
            title: i18n.t('patients.table.headers.name'),
            dataIndex: 'name',
            key: 'name',
            sorter: (a: ColumnType, b: ColumnType) => a.nameHidden.localeCompare(b.nameHidden)
        },
        {
            title: i18n.t('patients.table.headers.therapy'),
            dataIndex: 'therapy',
            key: 'therapy',
            sorter: (a: ColumnType, b: ColumnType) => a.therapy.localeCompare(b.therapy)
        },
        {
            title: i18n.t('patients.table.headers.cycle'),
            dataIndex: 'cycle',
            key: 'cycle',
            sorter: (a: ColumnType, b: ColumnType) => a.cycle - b.cycle
        },
        {
            title: i18n.t('patients.table.headers.day'),
            dataIndex: 'day',
            key: 'day',
            sorter: (a: ColumnType, b: ColumnType) => a.day - b.day
        }
    ]

    return (
        <div style={{marginTop: '1%', cursor: 'pointer'}}>
            <Table
                dataSource={dataSource}
                pagination={false}
                loading={props.loading}
                columns={columns}
                onRow={(record: ColumnType) => {
                    return {
                        onClick: () => {
                            therapyStore.setSelectedTherapyId(record.therapyId)
                            navigate(`${Routes.PATIENT}/${record.patientId}`)
                        }
                    }
                }}
            />
        </div>
    )
}

type PatientsTableProps = {
    patients: PatientTherapyRowDto[]
    loading?: boolean
}

type ColumnType = {
    key: string
    patientId: string
    name: React.ReactNode
    nameHidden: string
    therapy: string
    therapyId: string
    cycle: number
    day: number
}

export default PatientsTable
