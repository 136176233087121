/**
 * This hook is used to set a loading timeout or an error if the time is running out
 * @param initialValue
 * @param waitingTime
 */
import {Dispatch, SetStateAction, useEffect, useState} from 'react'

export const useLoading = (initialValue = false, waitingTime = 15000): [boolean, Dispatch<SetStateAction<boolean>>] => {
    const [isLoading, setIsLoading] = useState(initialValue)

    useEffect(() => {
        let timer = -1
        if (isLoading) {
            // @ts-ignore
            timer = setTimeout(() => {
                if (isLoading) {
                    setIsLoading(false)
                }
            }, waitingTime)
        }
        return () => {
            if (timer !== -1) {
                clearTimeout(timer)
            }
        }
    }, [isLoading])

    return [isLoading, setIsLoading]
}
