/**
 * Used for store all methods called when a search is made.
 * These methods will process the search response and will return the data in a custom format.
 */
import {
    DiagnosisType,
    DoctorSearchEntry,
    GenericSearch,
    ICD10SearchEntry,
    IngredientSearchEntry,
    MedicationSearchEntry,
    OptionItem,
    PatientGeneralInfo,
    PatientSearchEntry
} from '../../../utils/types'
import {toJson} from '../../../utils/helper'
import {SearchTypes} from '../../../utils/enums'

/**
 * Used to convert every patient from a search result to an OptinItem data type.
 * @param data the value of the one patient from search result
 */
const patientConverter = (data: PatientSearchEntry): OptionItem => {
    return {
        key: data.id,
        value: toJson(data),
        label: data.name
    }
}

/**
 * Used to convert every doctor from a search result to an OptinItem data type.
 * @param data the value of the one doctor from search result
 */
const doctorConverter = (data: DoctorSearchEntry): OptionItem => {
    return {
        key: data.id,
        value: toJson(data),
        label: data.name
    }
}

/**
 * Used to convert every icd10 from a search result to an OptinItem data type.
 * @param data the value of the one icd10 from search result
 */
const icd10Converter = (data: ICD10SearchEntry): OptionItem => {
    return {
        key: data.code,
        value: toJson(data),
        label: data.code + ' ' + data.titleOfficial
    }
}

/**
 * Used to convert every ingredient from a search result to an OptinItem data type.
 * @param data the value of the one ingredient from search result
 */
const ingredientsConverter = (data: IngredientSearchEntry): OptionItem => {
    return {
        key: data.id,
        value: toJson(data),
        label: data.name
    }
}

/**
 * Used to convert every medication from a search result to an OptinItem data type.
 * @param data the value of the one medication from search result
 */
const medicationConverter = (data: MedicationSearchEntry): OptionItem => {
    return {
        key: data.id,
        value: toJson(data),
        label: data.name + '\n' + (data.companyName || ''),
        displayLabel: data.name
    }
}

/**
 * Generic method used for handling search and processed search response. From a search response will be created an array of OptionItems.
 * @param response the search result, which is an array of values
 * @param type the type of the search (patient, doctor etc.)
 */
export const convertSearchResponse = (response: GenericSearch[], type: SearchTypes): OptionItem[] => {
    let data: OptionItem[] = []

    switch (type) {
        case SearchTypes.patient:
            data = (response as PatientSearchEntry[]).map(patientConverter)
            break
        case SearchTypes.doctor:
            data = (response as DoctorSearchEntry[]).map(doctorConverter)
            break
        case SearchTypes.icd10:
            data = (response as ICD10SearchEntry[]).map(icd10Converter)
            break
        case SearchTypes.ingredient:
            data = (response as IngredientSearchEntry[]).map(ingredientsConverter)
            break
        case SearchTypes.product:
            data = response
                .map(it => {
                    const item: MedicationSearchEntry = it as MedicationSearchEntry
                    return {
                        ...item,
                        originallyHadUnit: !!item.unit,
                        originallyHadDosage: !!item.dosage
                    }
                })
                .map(medicationConverter)
            break
    }

    return data
}

/**
 * Used to create a patient search dto from the entire patient entity.
 * @param patient the dto which will contains all patient informations
 */
export const patientPrefillConverter = (patient?: PatientGeneralInfo | null): PatientSearchEntry | null => {
    if (!patient) {
        return null
    }
    return {
        id: patient.id,
        name: `${patient.firstName} ${patient.lastName}`
    }
}

/**
 * Used to create an icd10 search dto from the entire icd10 entity.
 * @param diagnosis the dto which will contains all icd10 informations.
 */
export const icd10PrefillConverter = (diagnosis: DiagnosisType | null): ICD10SearchEntry | null => {
    if (!diagnosis) {
        return null
    }
    return {
        code: diagnosis.icdCode,
        titleOfficial: diagnosis.titleOfficial,
        medipolisName: diagnosis.medipolisName
    }
}
