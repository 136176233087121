import React from 'react'
import TherapyPlanTab from '../components/therapyplanTab/TherapyPlanTab'
import {useLoading} from '../../../utils/hooks'
import {ActionButtons} from '../../common'

/**
 * Component used in the therapy plan verification step displaying therapy plan data in a modal.
 */
const VerifyTherapyContent: React.FC<VerifyTherapyProps> = props => {
    const [loading, setLoading] = useLoading(false)
    const onSave = () => {
        setLoading(true)
        props.onSave()
        // we don't need to set it back to false since the callback closes and destroys the modal
    }
    return (
        <div>
            <TherapyPlanTab inVerifyModal={true} />
            <ActionButtons onCancel={props.onCancel} onSave={onSave} loadingSave={loading} />
        </div>
    )
}

type VerifyTherapyProps = {
    onCancel: () => void
    onSave: () => void
}

export default VerifyTherapyContent
