import {ITherapyService} from './utils/types'
import {AxiosRequestHeaders, AxiosResponse} from 'axios'
import {
    CompleteTherapyDto,
    OtherPrescriptionDto,
    RequestedPrescriptionDto,
    TherapyGeneralInfo,
    TherapyPrescriptionDto,
    UpdateObservationType
} from '../utils/types'
import {MedicationUrls} from '../store/utils/types'
import BaseService from './base_service'
import {useAuthHeader} from '../utils/hooks/useAuthHeader'

class TherapyService extends BaseService implements ITherapyService {
    async getTherapyById(id: string): Promise<AxiosResponse<CompleteTherapyDto>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.client.get(`/api/private/therapy/${id}/complete`, {
            headers: {
                ...authHeader
            }
        })
    }

    async createUpdateTherapy(therapy: TherapyGeneralInfo, therapyCloneId?: string): Promise<AxiosResponse<string>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        if (therapy.id) {
            return this.client.put(`/api/private/therapy/${therapy.id}`, therapy, {
                headers: {
                    ...authHeader
                }
            })
        }
        return this.client.post('/api/private/therapy/', therapy, {
            params: {
                therapyCloneId
            },
            headers: {
                ...authHeader
            }
        })
    }

    async createUpdateMedication(
        therapyId: string,
        medication: TherapyPrescriptionDto | RequestedPrescriptionDto | OtherPrescriptionDto,
        type: MedicationUrls
    ): Promise<AxiosResponse<void>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        if (medication.id) {
            return this.client.put(`/api/private/therapy/${therapyId}/${type}/${medication.id}`, medication, {
                headers: {
                    ...authHeader
                }
            })
        }
        return this.client.post(`/api/private/therapy/${therapyId}/${type}`, medication, {
            headers: {
                ...authHeader
            }
        })
    }

    async deleteMedication(therapyId: string, therapyMedId: string, type: MedicationUrls) {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.client.delete(`/api/private/therapy/${therapyId}/${type}/${therapyMedId}`, {
            headers: {
                ...authHeader
            }
        })
    }

    async generateReport(therapyId: string, patientId: string): Promise<AxiosResponse<any>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.client.get(`/api/private/report/${therapyId}/${patientId}`, {
            responseType: 'blob',
            headers: {
                ...authHeader
            }
        })
    }

    async exportTherapies(patientId: string): Promise<AxiosResponse<any>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.client.post(`/api/private/therapy/export/${patientId}`,{}, {
            headers: {
                ...authHeader
            }
        })
    }

    async closeTherapy(therapyId: string): Promise<AxiosResponse<void>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.client.patch(`/api/private/therapy/${therapyId}/close`,{}, {
            headers: {
                ...authHeader
            }
        })
    }

    async releaseTherapy(
        therapyId: string,
        sideEffects: UpdateObservationType[],
        vitalData: UpdateObservationType[]
    ): Promise<AxiosResponse<void>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.client.patch(`/api/private/therapy/${therapyId}/release`, {sideEffects, vitalData}, {
            headers: {
                ...authHeader
            }
        })
    }

    async requestToCheckTherapy(therapyId: string): Promise<AxiosResponse<void>> {
        const authHeader: AxiosRequestHeaders = await useAuthHeader()
        return this.client.patch(`/api/private/therapy/${therapyId}/to-check`,{}, {
            headers: {
                ...authHeader
            }
        })
    }
}

export default TherapyService
