import {useAuthToken} from '../../store'
import Cookies from 'js-cookie'
import {i18n} from '../../i18n'

/**
 * It is used to prepare header added to all axios request.
 * Here will tak the access token and will be added to the authorization header,
 */
export const useAuthHeader = async (headers?: any) => {
    const accessToken = await useAuthToken()

    return {
        Authorization: `Bearer ${accessToken}`,
        'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN'),
        'Accept-Language': i18n.locale,
        ...headers
    }
}
